<template>
  <div class="pins">
    <div v-show="navIsOpen">
      <transition-group name="pinfade" tag="ul" class="pins__container">
        <li
          class="pin"
          v-bind:class="{
            'is-hover': hoverId == navItemSub.id ? 1 : 0
          }"
          v-bind:style="{
            left: navItemSub.pos_x + '%',
            top: navItemSub.pos_y + '%',
            zIndex: navItemSub.index + 1,
            transitionDelay: navItemSub.index / 22 + 's'
          }"
          v-for="navItemSub in navDataSubCurrent"
          :key="navItemSub.id"
        >
          <router-link
            tag="button"
            v-bind:class="{
              hidden: navItemSub.pos_x > 0 && navItemSub.pos_y > 0 ? 0 : 1
            }"
            @mouseenter.native="updateHoverBt(navItemSub.id)"
            @mouseleave.native="updateHoverBt(0)"
            @click.native="updateHoverBt(0)"
            exact
            :to="{
              name: 'detail',
              params: {
                lang: $route.params.lang,
                id: navItemSub.id,
                slug: navItemSub.slug,
                pageTitle: navItemSub.pageTitle
              }
            }"
          >
            <div class="number">
              <span>{{ navItemSub.index }}</span>
            </div>
            <div class="bg">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 360 540"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve"
                xmlns:serif="http://www.serif.com/"
                style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
              >
                <path
                  d="M179.897,538.575C179.897,538.575 0,296.421 0,180C0,80.655 80.655,0 180,0C279.345,0 360,80.655 360,180C360,296.421 180.205,540 180.205,540C180.205,540 180,538.369 180,538.267L179.897,538.575Z"
                />
              </svg>
            </div>
          </router-link>
        </li>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navMain: Number,
    hoverId: Number,
    navDataSub: Object,
    navIsOpen: Boolean
  },
  computed: {
    navDataSubCurrent() {
      return this.navDataSub[this.navMain];
    }
  },
  methods: {
    updateHoverBt(item) {
      this.$emit("update-hover-bt", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.pin {
  position: absolute;
  width: 22px;
  height: 22px;
  overflow: visible;
  pointer-events: none;
  button {
    pointer-events: auto;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: transparent;
    transform: translate3d(-12px, -12px, 0);
    transition: transform 0.2s ease-in-out;
    &.hidden {
      display: none;
    }
  }
  .number {
    box-shadow: 0 0 8px $white;
    @include number(3, 0px, 0px);
  }
  .bg {
    display: none;
    @include absolute(0, auto, auto, 0, 2);
    > svg {
      width: 25px;
      height: 37.5px;
      display: block;
      path {
        fill: $white-a;
        transition: fill 0.2s;
      }
    }
  }
  @include breakpoint("m") {
    width: 25px;
    height: 37.5px;
    button {
      transform: translate3d(-12.5px, -37.5px, 0);
    }
    .number {
      box-shadow: none;
      @include number(3, 3px, 3px);
    }
    .bg {
      display: block;
    }
  }
  @include breakpoint("l") {
    width: 30px;
    height: 45px;
    .number {
      @include number(3, 4px, 4px);
    }
    .bg {
      > svg {
        width: 30px;
        height: 45px;
      }
    }
  }
}

// *** Hacks ***
// HzR-Hack
html.no-hzr .pin.is-hover {
  @include breakpoint("m") {
    z-index: 49;
    button {
      // transform: translate3d(-15px, -55px, 0);
    }
    .number {
      color: $black;
      background-color: $white;
    }
    .bg {
      > svg path {
        fill: $black;
        //fill: $white;
      }
    }
  }
}

// *** Transitions ***
.pin {
  transition: all 0.6s;
}
.pinfade-enter,
.pinfade-leave-to {
  opacity: 0;
  transform: translateY(-40px);
}
.pinfade-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.pinfade-leave-active {
  opacity: 0;
}
.pinfade-move {
  opacity: 1;
}
</style>
