<template>
  <div class="detail">
    <transition name="fade">
      <main v-if="loaded">
        <h1 v-html="langText(itemData.title)" />
        <article>
          <div class="text">
            <div v-html="langText(itemData.body)" />
          </div>
          <div class="images">
            <div class="figure__container">
              <figure
                v-for="itemDataBilder in itemData.bilder"
                :key="itemDataBilder.index"
              >
                <router-link
                  aria-label="Bild öffnen"
                  v-bind:tabindex="101 + itemDataBilder.index / 100"
                  exact
                  :to="{
                    name: 'detail_img',
                    params: {
                      lang: $route.params.lang,
                      id: $route.params.id,
                      imageIndex: itemDataBilder.index
                    }
                  }"
                >
                  <img
                    v-bind:src="itemDataBilder.url_thumb"
                    v-bind:srcset="
                      itemDataBilder.url_thumb +
                        ', ' +
                        itemDataBilder.url_large +
                        ' 860w'
                    "
                    v-bind:alt="langText(itemDataBilder.description)"
                    :class="{ isLarge: imgLength > 1 ? 0 : 1 }"
                  />
                </router-link>
                <figcaption>
                  <small>{{ langText(itemDataBilder.description) }}</small>
                </figcaption>
              </figure>
              <figure
                v-for="itemDataVideos in itemData.videos"
                :key="'v' + itemDataVideos.index"
              >
                <router-link
                  aria-label="Video öffnen"
                  v-bind:tabindex="161 + itemDataVideos.index / 100"
                  exact
                  :to="{
                    name: 'detail_video',
                    params: {
                      lang: $route.params.lang,
                      id: $route.params.id,
                      imageIndex: itemDataVideos.index
                    }
                  }"
                >
                  <img
                    v-bind:src="itemDataVideos.url_thumb"
                    v-bind:srcset="
                      itemDataVideos.url_thumb +
                        ', ' +
                        itemDataVideos.url_large +
                        ' 860w'
                    "
                    v-bind:alt="langText(itemDataVideos.description)"
                    :class="{ isLarge: imgLength > 1 ? 0 : 1 }"
                  />
                </router-link>
                <figcaption>
                  <small>{{ langText(itemDataVideos.description) }}</small>
                </figcaption>
              </figure>
            </div>
          </div>
        </article>
      </main>
    </transition>

    <Close
      page="detail"
      btClass="btn__white btn__border--black"
      btTabindex="100"
      :nav-is-loaded="loaded"
      :nav-is-open="true"
    />
    <NavI18n
      page="detail"
      btClass="btn__white btn__border--black"
      btTabindex="101"
      :nav-is-loaded="loaded"
      :nav-is-open="true"
    />
  </div>
</template>

<script>
import NavI18n from "@/components/NavI18n.vue";
import Close from "@/components/Close.vue";
export default {
  name: "detail",
  components: {
    NavI18n,
    Close
  },
  data() {
    return {
      loaded: false,
      itemData: [],
      imgLength: 0
    };
  },
  methods: {
    langText(text) {
      if (text) {
        return this.$route.params.lang == "de" ? text.de : text.en;
      }
    }
  },
  async mounted() {
    //fetch("https://www.stadtmodell-zuerich.ch/data/" + this.$route.params.id)
    //  .then(response => response.json())
    //  .then(data => {
    //    this.itemData = data;
    let data = window.__DATA__;
    let segment = this.$route.params.id;
    this.itemData = data[segment];
    if (this.itemData.id) {
      if (data.bilder) {
        this.imgLength = Object.keys(data.bilder).length;
      } else {
        this.imgLength = 0;
      }
      document.title = this.langText(this.itemData.title);
      this.loaded = true;
    } else {
      console.log("Error 404");
      this.$router.push({
        name: "detail",
        params: { id: 27, slug: this.itemData.slug }
      });
    }
    //});
  },
  watch: {
    $route(from, to) {
      document.title = this.langText(
        this.itemData.title,
        this.$route.params.lang
      );
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  display: block;
  max-width: 1920px;
  padding-top: 50px;
  padding-left: 6%;
  padding-right: 6%;
  margin: 0 auto;
  @include breakpoint("m") {
    padding-top: 20px;
    padding-left: 14%;
    padding-right: 14%;
  }
  @include breakpoint("l") {
    padding-top: 60px;
    padding-left: 100px;
    padding-right: 100px;
  }
}
article {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  .text {
    margin-bottom: 1rem;
  }
  @include breakpoint("l") {
    flex-wrap: nowrap;
    flex-direction: row;
    .text {
      width: 30%;
      padding-right: 0;
      margin-bottom: 0;
    }
    .images {
      width: 66%;
      padding-right: 5%;
    }
  }
}
h1 {
  max-width: rem-calc(1400);
}
.figure__container {
  @include breakpoint("s") {
    display: flex;
    flex-wrap: wrap;
  }
}
figure {
  display: table;
  margin: 0 0 20px 0;
  a {
    position: relative;
    display: inline-block;
    pointer-events: none;
  }
  img {
    width: 100%;
    height: auto;
    display: inline-block;
  }
  figcaption {
    display: table-caption;
    caption-side: bottom;
    padding-top: rem-calc(4);
  }
  @include breakpoint("s") {
    margin: 0 20px 30px 0;
    a {
      pointer-events: auto;
      // Zoom-Icon
      &::before {
        content: "";
        display: block;
        width: rem-calc(24);
        height: rem-calc(21);
        position: absolute;
        right: rem-calc(10);
        bottom: rem-calc(10);
        z-index: 2;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 72 63' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cg transform='matrix(2.66667,0,0,2.66667,4.66667,4.66664)'%3E%3Cpath d='M8.75,1.75L1.75,1.75L1.75,5.75L-1.75,5.75L-1.75,-1.75L8.75,-1.75L8.75,1.75Z' style='fill:white;'/%3E%3C/g%3E%3Cg transform='matrix(2.66667,0,0,2.66667,4.66667,58.3333)'%3E%3Cpath d='M1.75,-1.75L8.75,-1.75L8.75,1.75L-1.75,1.75L-1.75,-5.75L1.75,-5.75L1.75,-1.75Z' style='fill:white;'/%3E%3C/g%3E%3Cg transform='matrix(2.66667,0,0,2.66667,67.3333,4.66664)'%3E%3Cpath d='M1.75,5.75L-1.75,5.75L-1.75,1.75L-8.75,1.75L-8.75,-1.75L1.75,-1.75L1.75,5.75Z' style='fill:white;'/%3E%3C/g%3E%3Cg transform='matrix(2.66667,0,0,2.66667,67.3333,58.3333)'%3E%3Cpath d='M1.75,1.75L-8.75,1.75L-8.75,-1.75L-1.75,-1.75L-1.75,-5.75L1.75,-5.75L1.75,1.75Z' style='fill:white;'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    img {
      width: auto;
      height: 210px;
    }
  }
}

// *** Transitions ***
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
