var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail_video"},[(_vm.loaded)?_c('div',[_c('figure',{class:{ fadeInAnimation: this.imgloaded ? 1 : 0 }},[_c('span',[(_vm.prevImageIndex)?_c('router-link',{staticClass:"btn btn__rounded btn__white btn__border--black bt__step bt__step--prev",attrs:{"tag":"button","tabindex":"121","exact":"","to":{
            name: 'detail_img',
            params: {
              lang: _vm.$route.params.lang,
              id: _vm.$route.params.id,
              imageIndex: _vm.prevImageIndex
            }
          }}},[_c('svg',{staticStyle:{"fill-rule":"evenodd","clip-rule":"evenodd","stroke-linecap":"round","stroke-miterlimit":"1.5"},attrs:{"width":"100%","height":"100%","viewBox":"0 0 500 500","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","xml:space":"preserve","xmlns:serif":"http://www.serif.com/"}},[_c('g',{attrs:{"transform":"matrix(0.543478,0,0,0.543478,82.0652,114.13)"}},[_c('path',{staticStyle:{"fill":"none","stroke":"rgb(10,10,10)","stroke-width":"92px"},attrs:{"d":"M380,20L146,250L380,480"}})])])]):_vm._e(),_c('router-link',{style:({ pointerEvents: _vm.nextImageIndex ? 'auto' : 'none' }),attrs:{"tabindex":"122","exact":"","to":{
            name: 'detail_img',
            params: {
              lang: _vm.$route.params.lang,
              id: _vm.$route.params.id,
              imageIndex: _vm.nextImageIndex
            }
          }}},[_c('video',{class:'ratio--' + _vm.windowWidth,attrs:{"width":"1920","height":"1080","autoplay":"","muted":"","controls":"","playsinline":"","src":_vm.langText(_vm.thisImage.src)},domProps:{"muted":true},on:{"loadeddata":_vm.onImageLoad,"ended":_vm.onVideoEnded}})]),(_vm.nextImageIndex)?_c('router-link',{staticClass:"btn btn__rounded btn__white btn__border--black bt__step bt__step--next",attrs:{"tag":"button","tabindex":"123","exact":"","to":{
            name: 'detail_img',
            params: {
              lang: _vm.$route.params.lang,
              id: _vm.$route.params.id,
              imageIndex: _vm.nextImageIndex
            }
          }}},[_c('svg',{staticStyle:{"fill-rule":"evenodd","clip-rule":"evenodd","stroke-linecap":"round","stroke-miterlimit":"1.5"},attrs:{"width":"100%","height":"100%","viewBox":"0 0 500 500","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","xml:space":"preserve","xmlns:serif":"http://www.serif.com/"}},[_c('g',{attrs:{"transform":"matrix(-0.543478,-6.65569e-17,6.65569e-17,-0.543478,417.935,385.87)"}},[_c('path',{staticStyle:{"fill":"none","stroke":"rgb(10,10,10)","stroke-width":"92px"},attrs:{"d":"M380,20L146,250L380,480"}})])])]):_vm._e()],1),_c('figcaption',[_vm._v(" "+_vm._s(_vm.langText(_vm.thisImage.description))+" ")])])]):_vm._e(),_c('Close',{attrs:{"page":"detailVideo","btClass":"btn__white btn__border--black","btTabindex":"170","nav-is-loaded":_vm.loaded,"nav-is-open":true}}),_c('NavI18n',{attrs:{"page":"detail","btClass":"btn__white btn__border--black","btTabindex":"124","nav-is-loaded":_vm.loaded,"nav-is-open":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }