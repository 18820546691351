import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Detail from "@/views/Detail.vue";
import DetailImage from "@/views/DetailImage.vue";
import DetailVideo from "@/views/DetailVideo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:lang",
    name: "home",
    component: Home,
    props: true
  },
  {
    path: "/:lang/detail/:id/:slug",
    name: "detail",
    component: Detail,
    props: true
  },
  {
    path: "/:lang/detail/:id/:slug/img/:imageIndex",
    name: "detail_img",
    component: DetailImage,
    props: true
  },
  {
    path: "/:lang/detail/:id/:slug/video/:imageIndex",
    name: "detail_video",
    component: DetailVideo,
    props: true
  }
];

const router = new VueRouter({
  //mode: "history",
  routes
});

export default router;
