var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"nav__i18n"},[_c('transition',{attrs:{"name":"nav-fade"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.navIsLoaded && _vm.navIsOpen),expression:"navIsLoaded && navIsOpen"}],attrs:{"id":"nav__i18n"}},_vm._l((this.langs),function(val,key){return _c('li',{key:key},[_c('router-link',{staticClass:"btn btn__rounded is-text",class:[
            { 'is-selected': _vm.$route.params.lang == val.code ? 1 : 0 },
            _vm.btClass
          ],attrs:{"tag":"button","tabindex":parseInt(_vm.btTabindex) + key,"aria-label":val.text,"exact":"","to":{
            name: _vm.$route.name,
            params: { lang: val.code, id: _vm.$route.params.id }
          }}},[_c('span',[_vm._v(_vm._s(val.abbr))])])],1)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }