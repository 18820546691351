var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c('transition',{attrs:{"name":"nav-fade"}},[(_vm.loaded && _vm.navIsOpen)?_c('ul',[_c('li',[_c('router-link',{staticClass:"btn btn__large btn__black-a is-ausstellung",attrs:{"tabindex":"10.5","tag":"button","exact":"","to":{
            name: 'detail',
            params: {
              lang: _vm.$route.params.lang,
              id: this.ausstellungData.id,
              slug: this.ausstellungData.slug
            }
          }},domProps:{"textContent":_vm._s(_vm.langText(_vm.ausstellungData.name))}}),_c('router-link',{staticClass:"btn btn__rounded btn__white-a is-text is-info",attrs:{"tabindex":"10.5","tag":"button","exact":"","to":{
            name: 'detail',
            params: {
              lang: _vm.$route.params.lang,
              id: this.ausstellungData.id,
              slug: this.ausstellungData.slug
            }
          }}},[_c('span',[_vm._v("i")])])],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }