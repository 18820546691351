<template>
  <div class="home">
    <div class="home__fluid-wrapper">
      <Nav
        :nav-main="navMain"
        :nav-data-main="navDataMain"
        :nav-data-sub="navDataSub"
        :nav-data-inhalt="navDataInhalt"
        :hover-id="hoverId"
        :nav-is-loaded="loaded"
        :nav-is-open="navIsOpen"
        @update-nav-main="updateCurrentNavMain"
        @update-hover-bt="updateHoverBt"
      />
      <NavAusstellung :nav-is-open="navIsOpen" />
      <Pins
        v-if="navDataSub"
        :nav-main="navMain"
        :nav-data-sub="navDataSub"
        :nav-is-open="navIsOpen"
        :hover-id="hoverId"
        @update-hover-bt="updateHoverBt"
      />
      <Close
        page="home"
        btClass="btn__white-a"
        btTabindex="13"
        :nav-is-loaded="loaded"
        :nav-is-open="navIsOpen"
        @update-toggle-close="updateNavIsOpen"
      />
      <NavI18n
        page="home"
        btClass="btn__white-a"
        btTabindex="11"
        :nav-is-loaded="loaded"
        :nav-is-open="navIsOpen"
      />
      <Background />
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import NavAusstellung from "@/components/NavAusstellung.vue";
import NavI18n from "@/components/NavI18n.vue";
import Pins from "@/components/Pins.vue";
import Close from "@/components/Close.vue";
import Background from "@/components/Background.vue";

export default {
  name: "home",
  components: {
    Nav,
    NavAusstellung,
    NavI18n,
    Pins,
    Close,
    Background
  },
  data() {
    return {
      loaded: false,
      navIsOpen: true,
      navMain: 0,
      hoverId: 0,
      navDataMain: [],
      navDataSub: {},
      navDataSubCurrent: [],
      navDataInhalt: [],
      navDataAusstellung: []
    };
  },
  async mounted() {
    //fetch("https://www.stadtmodell-zuerich.ch/data/")
    //  .then(response => response.json())
    //  .then(data => {
    let data = window.__DATA__;
    this.pageTitle = data.title;
    this.navDataMain = data.main;
    this.navDataSub = data.sub;
    this.navDataInhalt = data.inhalt;
    this.navDataAusstellung = data.ausstellung;
    document.title = this.langText(this.pageTitle, this.$route.params.lang);
    this.loaded = true;
    //});*/
  },
  watch: {
    $route(from, to) {
      // Trigger wenn zurück zu home oder router == "home"
      if (from.name === "home") {
        document.title = this.langText(this.pageTitle, this.$route.params.lang);
      }
    }
  },
  methods: {
    langText(text) {
      if (text) {
        return this.$route.params.lang == "de" ? text.de : text.en;
      }
    },
    updateCurrentNavMain(navMain) {
      this.navMain = navMain;
      this.navDataSubCurrent = this.navDataSub[navMain];
    },
    updateHoverBt(hoverId) {
      this.hoverId = hoverId;
    },
    updateNavIsOpen(navIsOpen) {
      this.navIsOpen = navIsOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
// Sonst wird Nav nicht angezeigt
:root.is-hzr .home {
  min-height: 100vh;
}

.home {
  padding-top: 46px;
  background-color: $black;
  @include breakpoint("m") {
    padding-top: 0px;
    background-color: $white;
  }
  &__fluid-wrapper {
    position: relative;
    width: 100%;
    height: (56.25) * 1vw;
    box-shadow: 0 0 40px rgba($black, 0.4);
    @include breakpoint("m") {
      height: 100vh;
      min-height: 44rem; // height "Mittelalterliche Befestigung"
      max-height: (56.25 + 10) * 1vw; //10 fluid
    }
    @include breakpoint("l") {
      min-height: (56.25 - 10) * 1vw; //10 fluid
    }
  }
}
</style>
