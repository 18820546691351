var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav"},[_c('transition',{attrs:{"name":"nav-fade"}},[(_vm.navIsLoaded && _vm.navIsOpen && _vm.navDataMain && _vm.navDataMain.length)?_c('nav',{staticClass:"nav__main",class:{ hasSub: _vm.navDataSubCurrent ? 1 : 0 }},[_c('transition',{attrs:{"name":"subnav0-fade"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(!_vm.navDataSubCurrent),expression:"!navDataSubCurrent"}],staticClass:"nav__main--l0"},[_vm._l((_vm.navDataMain),function(navItemMain){return _c('li',{key:navItemMain.id},[_c('button',{staticClass:"btn btn__large btn__black-a",class:{ selected: _vm.navMain == navItemMain.id ? 1 : 0 },attrs:{"tabindex":navItemMain.index},domProps:{"innerHTML":_vm._s(_vm.langText(navItemMain.name))},on:{"click":function($event){return _vm.updateParentNavMain(navItemMain.id, navItemMain.index)}}})])}),_vm._l((_vm.navDataInhalt),function(navItemInhalt){return _c('li',{key:navItemInhalt.id},[_c('router-link',{staticClass:"btn btn__large btn__black-a",attrs:{"tag":"button","exact":"","to":{
                name: 'detail',
                params: {
                  lang: _vm.$route.params.lang,
                  id: navItemInhalt.id,
                  slug: navItemInhalt.slug
                }
              },"tabindex":"10"},domProps:{"textContent":_vm._s(_vm.langText(navItemInhalt.name))}})],1)})],2)]),_c('transition',{attrs:{"name":"subnav1-fade"}},[(_vm.navDataSubCurrent)?_c('ul',{staticClass:"nav__main--l1"},[_c('li',[_c('button',{staticClass:"btn btn__large btn__black-a has-number is-back",on:{"click":function($event){return _vm.updateParentNavMain(0)}}},[_vm._v(" "+_vm._s(_vm.langText(_vm.navDataMain[_vm.navIndexMain].name))+" "),_c('span',{staticClass:"number"},[_c('svg',{staticStyle:{"fill-rule":"evenodd","clip-rule":"evenodd","stroke-linecap":"round","stroke-miterlimit":"1.5"},attrs:{"width":"100%","height":"100%","viewBox":"0 0 500 500","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","xml:space":"preserve","xmlns:serif":"http://www.serif.com/"}},[_c('g',{attrs:{"transform":"matrix(0.543478,0,0,0.543478,82.0652,114.13)"}},[_c('path',{staticStyle:{"fill":"none","stroke":"rgb(10,10,10)","stroke-width":"92px"},attrs:{"d":"M380,20L146,250L380,480"}})])])])])]),_vm._l((_vm.navDataSubCurrent),function(navItemSub){return _c('li',{key:navItemSub.id},[_c('router-link',{staticClass:"btn btn__small btn__white-a",class:{
                hidden: navItemSub.visible ? 0 : 1,
                'is-hover': _vm.hoverId == navItemSub.id ? 1 : 0,
                'has-number': navItemSub.index ? 1 : 0
              },attrs:{"tag":"button","tabindex":_vm.navDataMain.length + navItemSub.index / 100,"exact":"","to":{
                name: 'detail',
                params: {
                  lang: _vm.$route.params.lang,
                  id: navItemSub.id,
                  slug: navItemSub.slug
                }
              }},nativeOn:{"mouseenter":function($event){return _vm.updateHoverBt(navItemSub.id)},"mouseleave":function($event){return _vm.updateHoverBt(0)},"click":function($event){return _vm.updateHoverBt(0)}}},[(navItemSub.index)?_c('span',{staticClass:"number"},[_c('span',[_vm._v(_vm._s(navItemSub.iconText ? navItemSub.iconText : navItemSub.index))])]):_vm._e(),_vm._v(" "+_vm._s(_vm.langText(navItemSub.name))+" ")])],1)})],2):_vm._e()])],1):_vm._e(),(!_vm.navIsLoaded || !_vm.navDataMain || !_vm.navDataMain.length)?_c('div',{staticClass:"nav__loading"},[_c('img',{attrs:{"src":require("@/assets/loading.gif"),"alt":"Lade Daten..."}})]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }