<template>
  <nav class="nav__i18n">
    <transition name="nav-fade">
      <ul v-show="navIsLoaded && navIsOpen" id="nav__i18n">
        <li v-for="(val, key) in this.langs" :key="key">
          <router-link
            tag="button"
            class="btn btn__rounded is-text"
            v-bind:tabindex="parseInt(btTabindex) + key"
            v-bind:class="[
              { 'is-selected': $route.params.lang == val.code ? 1 : 0 },
              btClass
            ]"
            :aria-label="val.text"
            exact
            :to="{
              name: $route.name,
              params: { lang: val.code, id: $route.params.id }
            }"
          >
            <span>{{ val.abbr }}</span>
          </router-link>
        </li>
      </ul>
    </transition>
  </nav>
</template>

<script>
export default {
  props: {
    btTabindex: String,
    btClass: String,
    navIsLoaded: Boolean,
    navIsOpen: Boolean
  },
  data() {
    return {
      langs: [
        { code: "de", abbr: "D", text: "Deutsch" },
        { code: "en", abbr: "E", text: "English" }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
nav {
  position: fixed;
  z-index: 290;
  right: 52px;
  top: 8px;
  > ul > li {
    display: inline-block;
    margin-top: 0px;
    margin-left: 10px;
  }
  @include breakpoint("m") {
    right: 20px;
    top: 62px;
    > ul > li {
      display: block;
      margin-top: 8px;
      margin-left: 0px;
    }
  }
  @include breakpoint("l") {
    top: 72px;
    > ul > li {
      margin-top: 10px;
    }
  }
  @include breakpoint("xl") {
    right: 30px;
    top: 98px;
    > ul > li {
      margin-top: 13px;
    }
  }
}
.nav-fade-enter-active,
.nav-fade-leave-active {
  transition: opacity 0.3s;
}
.nav-fade-enter,
.nav-fade-leave-to {
  opacity: 0;
}
</style>
