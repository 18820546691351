<template>
  <div class="detail_video">
    <div v-if="loaded">
      <figure v-bind:class="{ fadeInAnimation: this.imgloaded ? 1 : 0 }">
        <span>
          <router-link
            tag="button"
            class="btn btn__rounded btn__white btn__border--black bt__step bt__step--prev"
            tabindex="121"
            v-if="prevImageIndex"
            exact
            :to="{
              name: 'detail_img',
              params: {
                lang: $route.params.lang,
                id: $route.params.id,
                imageIndex: prevImageIndex
              }
            }"
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 500 500"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xml:space="preserve"
              xmlns:serif="http://www.serif.com/"
              style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-miterlimit:1.5;"
            >
              <g transform="matrix(0.543478,0,0,0.543478,82.0652,114.13)">
                <path
                  d="M380,20L146,250L380,480"
                  style="fill:none;stroke:rgb(10,10,10);stroke-width:92px;"
                />
              </g>
            </svg>
          </router-link>
          <router-link
            tabindex="122"
            v-bind:style="{ pointerEvents: nextImageIndex ? 'auto' : 'none' }"
            exact
            :to="{
              name: 'detail_img',
              params: {
                lang: $route.params.lang,
                id: $route.params.id,
                imageIndex: nextImageIndex
              }
            }"
          >
            <!-- <img
              v-bind:class="'ratio--' + windowWidth"
              v-bind:src="thisImage.url_large"
              v-bind:alt="langText(thisImage.description)"
              /> -->
            <video
              @loadeddata="onImageLoad"
              @ended="onVideoEnded"
              width="1920"
              height="1080"
              autoplay
              muted
              controls
              playsinline
              v-bind:class="'ratio--' + windowWidth"
              v-bind:src="langText(thisImage.src)"
            ></video>
          </router-link>
          <router-link
            tag="button"
            class="btn btn__rounded btn__white btn__border--black bt__step bt__step--next"
            tabindex="123"
            v-if="nextImageIndex"
            exact
            :to="{
              name: 'detail_img',
              params: {
                lang: $route.params.lang,
                id: $route.params.id,
                imageIndex: nextImageIndex
              }
            }"
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 500 500"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xml:space="preserve"
              xmlns:serif="http://www.serif.com/"
              style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-miterlimit:1.5;"
            >
              <g
                transform="matrix(-0.543478,-6.65569e-17,6.65569e-17,-0.543478,417.935,385.87)"
              >
                <path
                  d="M380,20L146,250L380,480"
                  style="fill:none;stroke:rgb(10,10,10);stroke-width:92px;"
                />
              </g>
            </svg>
          </router-link>
        </span>
        <figcaption>
          {{ langText(thisImage.description) }}
        </figcaption>
      </figure>
    </div>
    <Close
      page="detailVideo"
      btClass="btn__white btn__border--black"
      btTabindex="170"
      :nav-is-loaded="loaded"
      :nav-is-open="true"
    />
    <NavI18n
      page="detail"
      btClass="btn__white btn__border--black"
      btTabindex="124"
      :nav-is-loaded="loaded"
      :nav-is-open="true"
    />
  </div>
</template>

<script>
import NavI18n from "@/components/NavI18n.vue";
import Close from "@/components/Close.vue";

export default {
  name: "DetailVideo",
  components: {
    NavI18n,
    Close
  },
  data() {
    return {
      loaded: false,
      imgloaded: false,
      itemData: [],
      windowWidth: 0
    };
  },
  computed: {
    thisImageIndex: function() {
      return parseInt(this.$route.params.imageIndex);
    },
    thisImage: function() {
      return this.itemData.videos[this.thisImageIndex];
    },
    prevImageIndex: function() {
      if (this.itemData.videos[this.thisImageIndex - 1]) {
        return this.thisImageIndex - 1;
      } else {
        return false;
      }
    },
    nextImageIndex: function() {
      if (this.itemData.videos[this.thisImageIndex + 1]) {
        return this.thisImageIndex + 1;
      } else {
        return false;
      }
    },
    isHzr: function() {
      if (document.body.classList.contains("is-hzr")) {
        return true;
      } else {
        return false;
      }
    }
  },
  async mounted() {
    //fetch("https://www.stadtmodell-zuerich.ch/data/" + this.$route.params.id)
    //  .then(response => response.json())
    //  .then(data => {
    //    this.itemData = data;
    let data = window.__DATA__;
    let segment = this.$route.params.id;
    this.itemData = data[segment];
    if (this.itemData.id) {
      document.title = this.langText(this.itemData.title);
      this.loaded = true;
      if (!this.isHzr) {
        // Resize eventListener is active
        this.$nextTick(function() {
          //https://jsfiddle.net/hr77p7qb/3/
          window.addEventListener("resize", this.getWindowRatio);
          //Init
          this.getWindowRatio();
        });
      }
    } else {
      console.log("Error 404");
      this.$router.push({
        name: "detail",
        params: { id: 27, slug: this.itemData.slug }
      });
    }
    //});
  },
  methods: {
    getWindowRatio() {
      let imgRatio = this.thisImage.ratio;
      // 60 = Platz für Arrow-icons und mehrzeilige Bildbeschreibungen
      let appRatio =
        (document.documentElement.clientWidth + 60) /
        document.documentElement.clientHeight;
      this.windowWidth = imgRatio > appRatio ? "1" : "0";
    },
    langText(text) {
      if (text) {
        return this.$route.params.lang == "de" ? text.de : text.en;
      }
    },
    onImageLoad() {
      this.imgloaded = true;
      this.getWindowRatio();
    },
    onVideoEnded() {
      this.$router.push({
        name: "detail",
        params: { lang: this.$route.params.lang, id: this.$route.params.id }
      });
    }
  },
  beforeDestroy() {
    if (!this.isHzr) {
      window.removeEventListener("resize", this.getWindowRatio);
    }
  },
  watch: {
    $route(from, to) {
      document.title = this.langText(
        this.itemData.title,
        this.$route.params.lang
      );
      if (from.params.imageIndex !== to.params.imageIndex) {
        this.imgloaded = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.detail_video {
  @include overlay(120);
  display: flex;
  align-items: center;
  justify-content: center;
}
figure {
  display: table;
  &:not(.fadeInAnimation) {
    opacity: 0;
  }
  &.fadeInAnimation {
    animation-name: fadeIn;
    animation-duration: 0s;
  }
  > span {
    display: table-cell;
    a {
      outline: 0;
    }
    @include breakpoint("m") {
      position: relative;
    }
  }
  > figcaption {
    display: table-caption;
    caption-side: bottom;
    padding: 4px 10px 10px 10px;
    font-size: $font-size-s;
    @include breakpoint("m") {
      padding: 4px 0 0 0;
      font-size: $font-size-m;
    }
  }
}
.bt__step {
  top: 50%;
  position: absolute;
  svg path {
    stroke: $black !important;
  }
  &--prev {
    left: 10px;
    @include breakpoint("m") {
      left: -60px;
    }
    @include breakpoint("l") {
      left: -80px;
    }
    @include breakpoint("xl") {
      left: -100px;
    }
  }
  &--next {
    right: 10px;
    @include breakpoint("m") {
      right: -60px;
    }
    @include breakpoint("l") {
      right: -80px;
    }
    @include breakpoint("xl") {
      right: -100px;
    }
  }
}
.btn__white {
  background-color: $white-a;
  @include breakpoint("m") {
    background-color: $white;
  }
}
video {
  object-position: center;
  object-fit: contain;
  width: 100%;
  max-width: 100vw;
  height: auto;
  @include breakpoint("m") {
    max-width: calc(100vw - 160px);
  }
  @include breakpoint("l") {
    max-width: calc(100vw - 240px);
  }
}
video.ratio {
  &--0 {
    width: auto;
    max-height: calc(100vh - 160px);
    @include breakpoint("m") {
      max-height: calc(100vh - 260px);
    }
  }
  &--1 {
    max-width: 100vw;
    height: auto;
    @include breakpoint("m") {
      max-width: calc(100vw - 160px);
    }
    @include breakpoint("l") {
      max-width: calc(100vw - 240px);
    }
  }
}

// *** Animations ***
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
