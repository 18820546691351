<template>
  <nav class="nav__close">
    <ul v-if="navIsLoaded">
      <li>
        <button
          id="close"
          class="btn btn__close btn__rounded"
          v-bind:tabindex="btTabindex"
          :aria-label="label"
          v-bind:class="[{ 'is-selected': navIsOpen ? 0 : 1 }, btClass]"
          @click="clickOnClose(navIsOpen ? false : true)"
        >
          <svg
            class="icon--nav"
            width="100%"
            height="100%"
            viewBox="0 0 500 500"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
          >
            <g transform="matrix(0.592417,0,0,0.947867,108.412,289.502)">
              <path d="M28,48L450,48" />
            </g>
            <g transform="matrix(0.592417,0,0,0.947867,108.412,204.502)">
              <path d="M28,48L450,48" />
            </g>
            <g transform="matrix(0.592417,0,0,0.947867,108.412,119.502)">
              <path d="M28,48L450,48" />
            </g>
          </svg>

          <svg
            class="icon--cross"
            viewBox="0 0 500 500"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
          >
            <path d="M150,150L350,350" />
            <path d="M350,150L150,350" />
          </svg>
        </button>
      </li>
    </ul>
    <ul v-else>
      <li>
        <button class="btn btn__loading btn__rounded">
          <svg
            class="icon--cross"
            viewBox="0 0 500 500"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
          ></svg>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    page: String,
    btClass: String,
    btTabindex: String,
    navIsLoaded: Boolean,
    navIsOpen: Boolean
  },
  computed: {
    label() {
      if (this.$route.name == "home") {
        return "Navigation öffnen/schliessen";
      } else {
        return "Fenster schliessen";
      }
    }
  },
  methods: {
    clickOnClose(navIsOpen) {
      if (this.$route.name == "home") {
        this.$emit("update-toggle-close", navIsOpen);
      } else if (this.$route.name == "detail") {
        this.$router.push({
          name: "home",
          params: { lang: this.$route.params.lang }
        });
      } else if (
        this.$route.name == "detail_img" ||
        this.$route.name == "detail_video"
      ) {
        this.$router.push({
          name: "detail",
          params: { lang: this.$route.params.lang, id: this.$route.params.id }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  position: fixed;
  z-index: 300;
  right: 10px;
  top: 8px;
  @include breakpoint("m") {
    right: 20px;
    top: 20px;
  }
  @include breakpoint("xl") {
    right: 30px;
    top: 30px;
  }
  button {
    &.btn__loading {
      pointer-events: none;
      background: $white-a;
      animation: pulse_white 2s infinite;
      @include breakpoint("m") {
        animation: pulse_black 2s infinite;
      }
    }
    > svg.icon--nav {
      display: none;
      stroke: $white;
      stroke-width: rem-calc(40);
      fill: none;
    }
    > svg.icon--cross {
      display: inline-block;
      stroke: $black;
      stroke-width: rem-calc(50);
      fill: none;
    }
  }
  button.is-selected {
    > svg.icon--nav {
      display: inline-block;
    }
    > svg.icon--cross {
      display: none;
    }
  }
}

// *** Animations ***
@keyframes pulse_black {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba($black, 0.9);
  }
  70% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba($black, 0);
  }
  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba($black, 0);
  }
}
@keyframes pulse_white {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba($white, 0.9);
  }
  70% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba($white, 0);
  }
  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba($white, 0);
  }
}
</style>
