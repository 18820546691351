var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.loaded)?_c('main',[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.langText(_vm.itemData.title))}}),_c('article',[_c('div',{staticClass:"text"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.langText(_vm.itemData.body))}})]),_c('div',{staticClass:"images"},[_c('div',{staticClass:"figure__container"},[_vm._l((_vm.itemData.bilder),function(itemDataBilder){return _c('figure',{key:itemDataBilder.index},[_c('router-link',{attrs:{"aria-label":"Bild öffnen","tabindex":101 + itemDataBilder.index / 100,"exact":"","to":{
                  name: 'detail_img',
                  params: {
                    lang: _vm.$route.params.lang,
                    id: _vm.$route.params.id,
                    imageIndex: itemDataBilder.index
                  }
                }}},[_c('img',{class:{ isLarge: _vm.imgLength > 1 ? 0 : 1 },attrs:{"src":itemDataBilder.url_thumb,"srcset":itemDataBilder.url_thumb +
                      ', ' +
                      itemDataBilder.url_large +
                      ' 860w',"alt":_vm.langText(itemDataBilder.description)}})]),_c('figcaption',[_c('small',[_vm._v(_vm._s(_vm.langText(itemDataBilder.description)))])])],1)}),_vm._l((_vm.itemData.videos),function(itemDataVideos){return _c('figure',{key:'v' + itemDataVideos.index},[_c('router-link',{attrs:{"aria-label":"Video öffnen","tabindex":161 + itemDataVideos.index / 100,"exact":"","to":{
                  name: 'detail_video',
                  params: {
                    lang: _vm.$route.params.lang,
                    id: _vm.$route.params.id,
                    imageIndex: itemDataVideos.index
                  }
                }}},[_c('img',{class:{ isLarge: _vm.imgLength > 1 ? 0 : 1 },attrs:{"src":itemDataVideos.url_thumb,"srcset":itemDataVideos.url_thumb +
                      ', ' +
                      itemDataVideos.url_large +
                      ' 860w',"alt":_vm.langText(itemDataVideos.description)}})]),_c('figcaption',[_c('small',[_vm._v(_vm._s(_vm.langText(itemDataVideos.description)))])])],1)})],2)])])]):_vm._e()]),_c('Close',{attrs:{"page":"detail","btClass":"btn__white btn__border--black","btTabindex":"100","nav-is-loaded":_vm.loaded,"nav-is-open":true}}),_c('NavI18n',{attrs:{"page":"detail","btClass":"btn__white btn__border--black","btTabindex":"101","nav-is-loaded":_vm.loaded,"nav-is-open":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }