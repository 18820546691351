<template>
  <nav>
    <transition name="nav-fade">
      <ul v-if="loaded && navIsOpen">
        <li>
          <router-link
            class="btn btn__large btn__black-a is-ausstellung"
            tabindex="10.5"
            tag="button"
            v-text="langText(ausstellungData.name)"
            exact
            :to="{
              name: 'detail',
              params: {
                lang: $route.params.lang,
                id: this.ausstellungData.id,
                slug: this.ausstellungData.slug
              }
            }"
          />
          <router-link
            class="btn btn__rounded btn__white-a is-text is-info"
            tabindex="10.5"
            tag="button"
            exact
            :to="{
              name: 'detail',
              params: {
                lang: $route.params.lang,
                id: this.ausstellungData.id,
                slug: this.ausstellungData.slug
              }
            }"
          >
            <span>i</span>
          </router-link>
        </li>
      </ul>
    </transition>
  </nav>
</template>

<script>
export default {
  name: "NavAusstellung",
  props: {
    navIsOpen: Boolean
  },
  data() {
    return {
      loaded: false,
      ausstellungData: []
    };
  },
  methods: {
    langText(text) {
      if (text) {
        return this.$route.params.lang == "de" ? text.de : text.en;
      }
    },
    async getInfoData() {
      fetch("https://www.stadtmodell-zuerich.ch/data/1840")
        .then(response => response.json())
        .then(data => {
          this.ausstellungData = data;
          this.loaded = true;
        });
    },
    async getAusstellungData() {
      fetch("https://www.stadtmodell-zuerich.ch/data/1022")
        .then(response => response.json())
        .then(data => {
          this.ausstellungData = data;
          // Fetch Ausstellungs-Data im 5 Minuten Intervall
          setTimeout(() => this.getAusstellungData(), 1000 * 60 * 5);
          if (this.ausstellungData.id) {
            this.loaded = true;
            console.log("Lade Ausstellungs-Daten");
          } else {
            this.loaded = false;
          }
        });
    }
  },
  mounted() {
    // Ausstellungs-Data nur bei HzR
    /*if (document.body.classList.contains("is-hzr")) {
      this.getAusstellungData();
    } else {
      this.getInfoData();
    }*/
  }
};
</script>

<style lang="scss" scoped>
nav {
  @include absolute(auto, 10px, 10px, auto, 10);
  @include breakpoint("m") {
    right: 20px;
    bottom: 20px;
  }
  @include breakpoint("xl") {
    right: 30px;
    bottom: 30px;
  }
}
.is-info > span {
  transform: scaleY(1.1) translateY(0.05em);
}

// *** Transitions ***
.nav-fade-enter-active,
.nav-fade-leave-active {
  transition: opacity 0s;
}
.nav-fade-enter,
.nav-fade-leave-to {
  opacity: 0;
}

// *** Hacks ***
// Touchscreen-Hack
// Ausstellungen werden nur im HzR angezeigt
html.no-hzr {
  .is-info {
    display: block;
    visibility: visible;
  }
  .is-ausstellung {
    display: none;
    visibility: hidden;
  }
}
html.is-hzr {
  .is-info {
    display: none;
    visibility: hidden;
  }
  .is-ausstellung {
    display: block;
    visibility: visible;
  }
}
</style>
