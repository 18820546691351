<script>
export default {
  data() {
    return {
      // Avaliable languages
      langs: ["de", "en"]
    };
  },
  beforeMount() {
    this.checkLang(this.$route.params.lang);
  },
  watch: {
    $route(to) {
      this.checkLang(to.params.lang);
    }
  },
  methods: {
    checkLang(lang) {
      // Check if lang exists and lang is available
      if (!lang || !this.langs.includes(lang)) {
        this.$router.push({ name: "home", params: { lang: "de" } });
      }
      // Control lang-attribute
      // <html lang="x">
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", this.$route.params.lang);
    }
  },
  // No template
  render() {
    return "";
  }
};
</script>
