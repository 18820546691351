<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <keep-alive include="home">
        <!-- https://stackoverflow.com/a/51170320
        :key müsste aber den Wechsel der lang ignorieren
        -->
        <router-view :key="$route.params.id" />
      </keep-alive>
    </transition>
    <CheckLang />
  </div>
</template>

<script>
// @ is an alias to /src
import CheckLang from "@/components/_CheckLang.vue";
export default {
  components: {
    CheckLang
  },
  methods: {
    thisOs() {
      let os = navigator.platform.toLowerCase();
      if (os.indexOf("mac") != -1) {
        return "is-mac";
      } else if (os.indexOf("win") != -1) {
        return "is-win";
      } else {
        return "not-mac-win";
      }
    }
  },
  mounted() {
    document.body.classList.add(this.thisOs());
  }
};
</script>

<style lang="scss">
@import "@/styles/_fonts.scss";
@import "@/styles/_gui.scss";

// *** Transitions ***
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
