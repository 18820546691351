<template>
  <div class="nav">
    <transition name="nav-fade">
      <nav
        class="nav__main"
        v-if="navIsLoaded && navIsOpen && navDataMain && navDataMain.length"
        v-bind:class="{ hasSub: navDataSubCurrent ? 1 : 0 }"
      >
        <transition name="subnav0-fade">
          <ul class="nav__main--l0" v-show="!navDataSubCurrent">
            <!-- Nav Rubriken -->
            <li v-for="navItemMain in navDataMain" :key="navItemMain.id">
              <button
                class="btn btn__large btn__black-a"
                v-html="langText(navItemMain.name)"
                v-bind:tabindex="navItemMain.index"
                v-bind:class="{ selected: navMain == navItemMain.id ? 1 : 0 }"
                @click="updateParentNavMain(navItemMain.id, navItemMain.index)"
              />
            </li>
            <!-- Nav Details -->
            <li v-for="navItemInhalt in navDataInhalt" :key="navItemInhalt.id">
              <router-link
                class="btn btn__large btn__black-a"
                tag="button"
                v-text="langText(navItemInhalt.name)"
                exact
                :to="{
                  name: 'detail',
                  params: {
                    lang: $route.params.lang,
                    id: navItemInhalt.id,
                    slug: navItemInhalt.slug
                  }
                }"
                tabindex="10"
              />
            </li>
          </ul>
        </transition>

        <transition name="subnav1-fade">
          <ul class="nav__main--l1" v-if="navDataSubCurrent">
            <li>
              <button
                class="btn btn__large btn__black-a has-number is-back"
                @click="updateParentNavMain(0)"
              >
                {{ langText(navDataMain[navIndexMain].name) }}

                <span class="number">
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 500 500"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    xmlns:serif="http://www.serif.com/"
                    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-miterlimit:1.5;"
                  >
                    <g transform="matrix(0.543478,0,0,0.543478,82.0652,114.13)">
                      <path
                        d="M380,20L146,250L380,480"
                        style="fill:none;stroke:rgb(10,10,10);stroke-width:92px;"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </li>
            <li v-for="navItemSub in navDataSubCurrent" :key="navItemSub.id">
              <router-link
                tag="button"
                class="btn btn__small btn__white-a"
                v-bind:tabindex="navDataMain.length + navItemSub.index / 100"
                v-bind:class="{
                  hidden: navItemSub.visible ? 0 : 1,
                  'is-hover': hoverId == navItemSub.id ? 1 : 0,
                  'has-number': navItemSub.index ? 1 : 0
                }"
                @mouseenter.native="updateHoverBt(navItemSub.id)"
                @mouseleave.native="updateHoverBt(0)"
                @click.native="updateHoverBt(0)"
                exact
                :to="{
                  name: 'detail',
                  params: {
                    lang: $route.params.lang,
                    id: navItemSub.id,
                    slug: navItemSub.slug
                  }
                }"
              >
                <span class="number" v-if="navItemSub.index">
                  <span>{{
                    navItemSub.iconText ? navItemSub.iconText : navItemSub.index
                  }}</span>
                </span>
                {{ langText(navItemSub.name) }}
              </router-link>
            </li>
          </ul>
        </transition>
      </nav>
      <div
        class="nav__loading"
        v-if="!navIsLoaded || !navDataMain || !navDataMain.length"
      >
        <img src="~@/assets/loading.gif" alt="Lade Daten..." />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navIndexMain: -1
    };
  },
  props: {
    navMain: Number,
    hoverId: Number,
    navDataMain: Array,
    navDataInhalt: Array,
    navDataSub: Object,
    navIsLoaded: Boolean,
    navIsOpen: Boolean
  },
  computed: {
    navDataSubCurrent() {
      return this.navDataSub[this.navMain];
    }
  },
  methods: {
    updateParentNavMain(item, index) {
      this.$emit("update-nav-main", item);
      this.navIndexMain = index - 1;
    },
    updateHoverBt(item) {
      this.$emit("update-hover-bt", item);
    },
    langText(text) {
      if (text) {
        return this.$route.params.lang == "de" ? text.de : text.en;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  align-content: flex-start;
  position: absolute;
  left: 0;
  top: (56.25) * 1vw;
  z-index: 1; //pins sind bis 49
  width: 100%;
  overflow-x: hidden;
  @include breakpoint("m") {
    align-items: flex-end;
    width: 300px;
    left: 20px;
    bottom: 20px;
    top: auto;
    overflow-x: hidden;
    z-index: 50; //pins sind bis 49
  }
  @include breakpoint("xl") {
    width: 315px;
    left: 30px;
    bottom: 30px;
  }
  > ul {
    flex: 0 0 100%;
    position: relative;
    width: 100%;
    &.nav__main--l0 {
      button {
        border-bottom: 1px #444 solid;
      }
    }
    &.nav__main--l1 {
      button {
        border-bottom: 1px #ccc solid;
      }
    }
    @include breakpoint("m") {
      > li:last-child button {
        border-bottom: none;
      }
    }
  }
  button {
    display: block;
    width: 100%;
    transition: background-color 0.2s;
    &.has-number {
      padding-left: 38px;
    }
    .number {
      @include number(3, 6px, 9px);
    }
    &.hidden {
      display: none;
    }
    &.is-back {
      > .number {
        background-color: $white;
        svg g {
          stroke: $black;
        }
      }
    }
  }
}
.nav__loading {
  position: absolute;
  bottom: -136px;
  left: 0;
  width: 100vw;
  text-align: center;
  @include breakpoint("m") {
    display: none;
  }
}

// *** Responsive DOM ***
.btn {
  &__small .number {
    top: 5px;
  }
  &__large .number {
    top: 6px;
  }
  @include breakpoint("m") {
    &__small .number {
      top: 2px;
    }
    &__large .number {
      top: 5px;
    }
  }
  @include breakpoint("l") {
    &__small .number {
      top: 1px;
    }
  }
  @include breakpoint("xl") {
    &__small .number {
      top: 6px;
    }
    &__large .number {
      top: 12px;
    }
  }
}

// *** Hacks ***
html.no-hzr nav ul.nav__main--l1 button.is-hover {
  background-color: $white;
}

// *** Transitions ***
.nav-fade-enter-active,
.nav-fade-leave-active {
  transition: opacity 0.2s;
}
.nav-fade-enter,
.nav-fade-leave-to {
  opacity: 0;
}

.subnav0-fade-enter-active,
.subnav0-fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.subnav0-fade-enter,
.subnav0-fade-leave-to {
  margin-left: -100%;
}

.subnav1-fade-enter-active,
.subnav1-fade-leave-active {
  transition: 0.3s ease-in-out;
}
</style>
